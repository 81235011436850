export const ROUTES = {
  HOME: '/',
  AUTH: { SIGN_IN: '/auth/sign-in' },
  DASHBOARD: {
    OVERVIEW: '/my-page',
  },
  MY_PAGE: {
    ROOT: '/my-page',
  },
  SETTING: {
    PROFILE: '/my-page/setting/profile',
    PASSWORD: '/my-page/setting/password',
  },
  QUESTION: {
    ROOT: '/my-page/question',
    BY_ID: (id: string) => `/question/${id}`,
    TRASH: '/my-page/question/trash',
    GENERATOR: '/question/generator',
  },
  TEST_PAPER: {
    ROOT: '/my-page/test-paper',
    LIST: '/my-page/test-paper',
    CREATE: '/my-page/test-paper/create',
    EDIT: (id: string) => `/test-paper/${id}/edit`,
    PDF: (id: string) => `/test-paper/pdf/${id}`,
  },

  TEMP: {
    ADD_ALLOW_EXCEPTION_WORD: '/add-allow-exception-word',
    DELETE_ANNOTATION: '/delete-annotation',
    ADD_ANNOTATION: '/add-annotation',
    REPLACE_WORD: '/replace-word',
    DELETE_ALLOW_EXCEPTION_WORD: '/delete-allow-exception-word',
  },
} as const;
