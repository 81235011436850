import Image, { ImageProps } from 'next/image';
import MainLogoImage from '$/images/logo/q-craft-logo.webp';
import React from 'react';
import { cn } from '@/shared/lib';

export const MainLogo = ({
  className,
  ...props
}: Omit<ImageProps, 'src' | 'alt'>) => {
  return (
    <Image
      src={MainLogoImage}
      alt="로고"
      className={cn('h-full w-auto', className)}
      {...props}
    />
  );
};
