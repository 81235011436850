export const API_ENDPOINTS = {
  USERS: {
    CURRENT: `/users/me`,
    SUBSCRIPTION: '/users/subscription',
  },
  AUTH: {
    CHANGE_PASSWORD: `/auth/change-password`,
    LOGIN: {
      EMAIL: `/auth/login/email`,
      TWO_FACTOR: `/auth/login/two-factor`,
    },
    SEND_CODE: `/auth/two-factor/send`,
    LOGOUT: `/auth/logout`,
    REGISTER: {
      EMAIL: `/auth/register/email`,
      TWO_FACTOR: `/auth/register/two-factor`,
    },
    REFRESH_TOKEN: `/auth/refresh`,
  },
  QUESTIONS: {
    ROOT: '/questions',
    BY_ID: (id: number) => `/questions/${id}`,
    COUNT: `/questions/count`,
    DRAFT: `/questions/draft`,
    CREATION: `/questions/creation`,
    QUOTATION: `/questions/quotation`,
    PARAPHRASE: `/questions/paraphrase`,
    STATE: (id: number) => `/questions/${id}/state`,
  },
  WORDS: {
    SUGGEST_REPLACE: `/word/suggest-replace`,
  },
  CHOICES: {
    GENERATE: `/choices/generate`,
  },
  TOPICS: '/topics',
  QUESTION_TYPES: '/question-types',
  GRADES: '/grades',
  TEST_PAPERS: {
    ROOT: '/test-papers',
    BY_ID: (id: number) => `/test-papers/${id}`,
    COUNT: `/test-papers/count`,
  },
} as const;
