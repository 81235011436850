import React from 'react';
import { Card } from '@/shared/ui';
import { cn } from '@/shared/lib';

type Props = {
  title: React.ReactNode;
  content: React.ReactNode;
  className?: string;
};

export function InfoCard({ title, content, className }: Props) {
  return (
    <Card
      className={cn(
        'flex h-32 flex-col items-center justify-center gap-y-2 shadow-lg drop-shadow',
        className,
      )}
    >
      <h1 className="text-xl font-bold">{title}</h1>
      <p>{content}</p>
    </Card>
  );
}

