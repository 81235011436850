import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from '@/shared/const';

const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL;

export const apiClint = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60 * 1000,
  withCredentials: true, // 모든 요청에 쿠키 포함
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authApiClint = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60 * 1000,
  withCredentials: true, // 모든 요청에 쿠키 포함
  headers: { 'Content-Type': 'application/json' },
});

apiClint.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  async (error) => {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      return Promise.reject({
        statusCode: 500,
        message: '서버로부터 응답이 없습니다.',
        timestamp: new Date().toISOString(),
        path: error.config?.url,
      });
    } else {
      // 요청 설정 중에 오류가 발생한 경우
      return Promise.reject({
        statusCode: 500,
        message: '요청 중 오류가 발생했습니다: ' + error.message,
        timestamp: new Date().toISOString(),
        path: error.config?.url,
      });
    }
  },
);

authApiClint.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        try {
          await axios.post(
            API_BASE_URL + API_ENDPOINTS.AUTH.REFRESH_TOKEN,
            null,
            {
              withCredentials: true,
            },
          );
          return authApiClint.request(error.config);
        } catch (error) {
          await axios.post(API_BASE_URL + API_ENDPOINTS.AUTH.LOGOUT, null, {
            withCredentials: true,
          });
        }
      }
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // 요청이 이루어졌으나 응답을 받지 못한 경우
      return Promise.reject({
        statusCode: 500,
        message: '서버로부터 응답이 없습니다.',
        timestamp: new Date().toISOString(),
        path: error.config?.url,
      });
    } else {
      // 요청 설정 중에 오류가 발생한 경우
      return Promise.reject({
        statusCode: 500,
        message: '요청 중 오류가 발생했습니다: ' + error.message,
        timestamp: new Date().toISOString(),
        path: error.config?.url,
      });
    }
  },
);
