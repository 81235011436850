'use client';

import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import { useEffect, useState } from 'react';
import { Loader } from 'lucide-react';
import { cn } from '@/shared/lib';

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(
      'relative h-4 w-full overflow-hidden rounded-full bg-secondary',
      className,
    )}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className="h-full w-full flex-1 bg-primary transition-all"
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    />
  </ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

function ProgressBySecond({ seconds }: { seconds: number }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();
    const endTime = startTime + seconds * 1000;
    const updateInterval = 200; // 업데이트 간격을 200ms로 설정
    let lastUpdateTime = startTime;

    const updateProgress = () => {
      const now = Date.now();
      const timeElapsed = now - startTime;
      const sinceLastUpdate = now - lastUpdateTime;
      const duration = endTime - startTime;

      if (sinceLastUpdate >= updateInterval) {
        // 지정된 업데이트 간격 이후에만 진행률 업데이트
        const progress = timeElapsed / duration;
        const easedProgress = progress * (2 - progress); // 단순화된 ease-out 곡선

        setProgress(easedProgress * 100);
        lastUpdateTime = now;
      }

      if (timeElapsed < duration) {
        requestAnimationFrame(updateProgress);
      } else {
        setProgress(100); // 마지막에는 진행률을 100%로 설정
      }
    };

    requestAnimationFrame(updateProgress);

    return () => {
      setProgress(0); // 컴포넌트가 언마운트되면 진행률을 초기화
    };
  }, []);

  return (
    <div className="flex flex-col">
      {progress >= 100 ? (
        <div className="flex flex-col items-center gap-y-2 text-center">
          <Loader className="h-10 w-10 animate-pulse" />
          생성된 문항을 정리중입니다. 잠시만 기다려주세요.
        </div>
      ) : (
        <>
          <Progress value={progress} className="w-full" />
          <div className="text-center">{progress.toFixed(2)}% 생성 완료</div>
        </>
      )}
    </div>
  );
}

export { Progress, ProgressBySecond };
